import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import { randomNumber } from "@/app/_utils/helper";
import Image from "next/image";
import { cn } from "@/app/_utils/ui";

interface ArticleRandomProps {
  className?: string;
  onSelectArticle: (articleId: string) => void;
  variant?: "default" | "dropdown";
}

const ArticleRandom: React.FC<ArticleRandomProps> = ({
  className,
  onSelectArticle,
  variant,
}) => {
  const [spinning, setSpinning] = useState(false);
  const articles = useSelector((state: RootState) => state.articles).articles;
  const userReadArticles = useSelector(
    (state: RootState) => state.userReadArticles,
  );

  const getRandomArticle = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: "get_random_article" });
    }
    setSpinning(true);
    setTimeout(() => {
      const keys = Object.keys(articles);
      const readKeys = userReadArticles?.articles
        ? Object.keys(userReadArticles.articles)
        : [];
      const excludeKeys = keys.filter((key) => {
        if (!articles[key].status) {
          // if there is no status data, do not exclude
          return false;
        } else {
          return articles[key].status?.vault || !articles[key].status.published;
        }
      });

      const filteredKeys = keys.filter(
        (key) => !readKeys.includes(key) && !excludeKeys.includes(key),
      );

      let articleIndex: number;
      let articleId: string;

      if (filteredKeys.length > 0) {
        // If there are filtered keys, select a random one from them
        articleIndex = randomNumber(filteredKeys.length - 1);
        articleId = filteredKeys[articleIndex];
      } else {
        // If there are no filtered keys, select a random one from all keys
        articleIndex = randomNumber(keys.length - 1);
        articleId = keys[articleIndex];
      }
      onSelectArticle(articleId);

      setSpinning(false);
    }, 1000);
  };

  return (
    <div
      className={cn(
        "flex justify-between items-center -z-1 w-full",
        variant === "dropdown" && "p-4 bg-neutral-200 cursor-pointer",
        className,
      )}
      onClick={variant === "dropdown" ? getRandomArticle : undefined}
    >
      <div className="flex flex-col">
        <div
          className={cn(
            "text-left font-medium",
            variant === "dropdown" ? "text-base" : "text-lg",
          )}
        >
          Not sure what to read?
        </div>
        <div className="text-left text-neutral-700 text-sm">
          Spin the wheel to fetch your next read.
        </div>
      </div>
      <button id="getRandomArticle" onClick={getRandomArticle}>
        <Image
          width={48}
          height={48}
          alt="loading"
          src="/assets/images/spinner.svg"
          className={cn(
            variant === "dropdown" ? "w-10 h-10" : "w-15 h-15",
            spinning && "animate-spin",
          )}
        />
      </button>
    </div>
  );
};

export default ArticleRandom;
